/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // When the user scrolls the page, execute addStickyHeader 
        window.onscroll = function() {addStickyHeader()};

        // Get the navbar
        var navbar = document.getElementById("header");

        // Get the offset position of the navbar
        var sticky = navbar.offsetTop;

        // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
        function addStickyHeader() {
          if (window.pageYOffset > sticky) {
            navbar.classList.add("header--sticky");
          } else {
            navbar.classList.remove("header--sticky");
          }
        }


      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        jQuery('.full-hero__slider').carouFredSel({
          items: 1,
          infinite: true,
          circular: true,
          responsive : true,
          scroll : { fx : "crossfade",duration: 1000 },
          items: 1
        });

        jQuery('.gallery-slider').owlCarousel({
          items: 1,
          loop: true,
          lazyLoad: true,
          pagination: false,
          autoPlay: true,
          responsive: true,
          singleItem: true,
          autoPlayTimeout: 2000,
          autoplayHoverPause: true,
          navigation: true,
          navigationText : ["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"]
        });

      }
    },
    'residences': {
      init: function() {
        //Video Scroll
        var isInViewport = function (elem) {
            var bounding = elem.getBoundingClientRect();
            return (
                bounding.top >= 0 &&
                bounding.left >= 0 &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        };
        //Listener
        var video = document.getElementById('video');
        window.addEventListener('scroll', function (event) {
          if (isInViewport(video)) {
            video.play();
          }
        }, false);

      }
    },
    'amenities': {
      init: function() {
        // JavaScript to be fired
        //Video Scroll
        var isInViewport = function (elem) {
            var bounding = elem.getBoundingClientRect();
            return (
                bounding.top >= 0 &&
                bounding.left >= 0 &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        };

        //Listener
        var video = document.getElementById('video');
        window.addEventListener('scroll', function (event) {
          if (isInViewport(video)) {
            video.play();
          }
        }, false);
      }
    },
    'neighborhood': {
      init: function() {
        // JavaScript to be fired
        //Video Scroll
        var isInViewport = function (elem) {
            var bounding = elem.getBoundingClientRect();
            return (
                bounding.top >= 0 &&
                bounding.left >= 0 &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        };

        //Listener
        var video = document.getElementById('video');
        window.addEventListener('scroll', function (event) {
          if (isInViewport(video)) {
            video.play();
          }
        }, false);

        ///Second video listener and view scroll

        //Video Scroll
        var isInVideoArea = function (elem) {
            var boundingv = elem.getBoundingClientRect();
            return (
                boundingv.top >= 0 &&
                boundingv.left >= 0 &&
                boundingv.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                boundingv.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        };

        //Listener
        var videoalt = document.getElementById('videoalt');
        window.addEventListener('scroll', function (event) {
          if (isInVideoArea(videoalt)) {
            videoalt.play();
          }
        }, false);
      }
    },
    'gallery': {
      init: function() {
        // JavaScript to be fired
        jQuery(document).ready(function($){
          function lightboxPhoto() {
            jQuery("a[rel^='prettyPhoto']").prettyPhoto({
                animationSpeed:'fast',
                slideshow:5000,
                theme:'light_rounded',
                show_title:false,
                overlay_gallery: false
              });
            
            }
            if(jQuery().prettyPhoto) {
              lightboxPhoto();  
            }
            if (jQuery().quicksand) {
              // Clone applications to get a second collection
              var $data = $(".portfolio-area").clone();
              //NOTE: Only filter on the main portfolio page, not on the subcategory pages
              $('.portfolio-categ li').click(function(e) {
                $(".filter li").removeClass("active");  
                // Use the last category class as the category to filter by. This means that multiple categories are not supported (yet)
                var filterClass=$(this).attr('class').split(' ').slice(-1)[0];
                
                if (filterClass == 'all') {
                  var $filteredData = $data.find('.portfolio-item2');
                } else {
                  var $filteredData = $data.find('.portfolio-item2[data-type=' + filterClass + ']');
                }
                $(".portfolio-area").quicksand($filteredData, {
                  duration: 600,
                  adjustHeight: 'auto'
                }, function () {

                    lightboxPhoto();
                        });   
                $(this).addClass("active");       
                return false;
              });
          }//if quicksand
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
